import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Careers() {
  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontFamily: 'Noto Sans',
          color: 'secondary.main',
          mb: 6,
          mt: 6,
        }}
      >
        CAREERS
      </Typography>
      <Typography>
        Come join the small and dynamic R2I Defense team and be a part of family and not a just a
        number! R2I is a Department of Defense contractor focusing on contracting and cyber tool
        development from Research and Development (R&D) to Operational where we value Respect,
        Responsibility, and Integrity.
      </Typography>
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontFamily: 'Noto Sans',
          color: 'secondary.main',
          mb: 6,
          mt: 6,
        }}
      >
        CURRENT OPENINGS
      </Typography>
      <Typography sx={{ mt: 2.0 }}>
        Currently, R2I has no active open job requisitions. Candidates with a strong engineering
        or cybersecurity background may email info at r2idef dot com to learn about potential
        future opportunities.
      </Typography>
    </Container>
  );
}
