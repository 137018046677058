import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';

export default function HomeCard(props) {
  const { header, text } = props;
  return (
    <Stack alignItems="center" justifyContent="center" spacing={2} sx={{ height: '100%' }}>
      <Container>
        <Typography
          variant="h5"
          align="center"
          sx={{
            fontFamily: 'Noto Sans',
            color: 'secondary.main',
            pt: 2,
          }}
        >
          {header}
        </Typography>
        <Typography align="center" sx={{ pb: 2 }}>
          {text}
        </Typography>
      </Container>
    </Stack>
  );
}

HomeCard.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
