import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import BoltIcon from '@mui/icons-material/Bolt';
import SendIcon from '@mui/icons-material/Send';
import HandshakeIcon from '@mui/icons-material/Handshake';
import TerminalIcon from '@mui/icons-material/Terminal';
import CircleIcon from '@mui/icons-material/Circle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeCard from './HomeCard';

export default function Home() {
  return (
    <>
      <Stack
        alignItems="center"
        sx={{
          backgroundColor: 'black', backgroundImage: 'url("eagle.jpg")', backgroundSize: 'cover', pt: 18, pb: 18,
        }}
      >
        <Box component="img" src="R2I_Stack_Gradient.png" alt="" sx={{ height: 300, mb: 6 }} />
        <Typography variant="h6" color="white" align="center" sx={{ ml: 2, mr: 2 }}>
          We are a Department of Defense premier contractor with a focus on Respect,
          Responsibility, and Integrity!
        </Typography>
      </Stack>
      <Stack id="about" alignItems="center" sx={{ backgroundColor: '#f2f2f2', pt: 12, pb: 12 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontFamily: 'Noto Sans',
            color: 'secondary.main',
            mb: 6,
          }}
        >
          ABOUT US
        </Typography>
        <Typography variant="h6" align="center" sx={{ ml: 2, mr: 2 }}>
          We focus on DoD contracting and cyber tool development from Research and
          Development (R&D) to Operational.
        </Typography>
      </Stack>
      <Box
        sx={{
          backgroundColor: 'black', backgroundImage: 'url("FLAG.jpg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', pt: 12, pb: 12,
        }}
      >
        <Container>
          <Grid container spacing={0} sx={{ backgroundColor: 'black' }}>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'white' }}>
              <HomeCard header="CONTRACTING SERVICES" text="Our contracting is focused on lightning fast execution" />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'primary.main', display: { xs: 'none', sm: 'initial' } }}>
              <Stack alignItems="center" sx={{ mt: 6, mb: 6 }}>
                <BoltIcon sx={{ color: 'white', fontSize: 100 }} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'white' }}>
              <HomeCard header="FAST COMMUNICATION" text="We use technology that just works" />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'primary.main', display: { xs: 'none', sm: 'initial' } }}>
              <Stack alignItems="center" sx={{ mt: 6, mb: 6 }}>
                <SendIcon sx={{ color: 'white', fontSize: 100 }} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'primary.main', display: { xs: 'none', sm: 'initial' } }}>
              <Stack alignItems="center" sx={{ mt: 6, mb: 6 }}>
                <HandshakeIcon sx={{ color: 'white', fontSize: 100 }} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'white' }}>
              <HomeCard header="RESPECT" text="We respect out clients and make their problems ours" />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'primary.main', display: { xs: 'none', sm: 'initial' } }}>
              <Stack alignItems="center" sx={{ mt: 6, mb: 6 }}>
                <TerminalIcon sx={{ color: 'white', fontSize: 100 }} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ backgroundColor: 'white' }}>
              <HomeCard header="CYBER OPERATIONS" text="We provide operational and mission-ready software solutions" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Stack id="experience" alignItems="center" sx={{ backgroundColor: '#f2f2f2', pt: 12, pb: 12 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontFamily: 'Noto Sans',
            color: 'secondary.main',
            mb: 6,
          }}
        >
          EXPERIENCE THAT MATTERS
        </Typography>
        <Typography variant="h6" align="center" sx={{ ml: 2, mr: 2 }}>
          Our team has over 50 years of experience with a focus on rapid customer expectation
          management through dynamic contracting.
        </Typography>
      </Stack>
      <Box sx={{ backgroundColor: '#abaca7' }}>
        <Container>
          <Grid container spacing={0} sx={{ backgroundColor: '#abaca7', pt: 9, pb: 12 }}>
            <Grid item md={12} lg={6}>
              <Typography variant="h5" color="primary.main" sx={{ fontFamily: 'Noto Sans', mt: 3 }}>Contracting Team Expertise</Typography>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary="Vehicle types: IDIQ, OTA and GSA" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary="Contract types: CPFF, FFP, T&M and PO" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary="Contract values from $30k SBIRs up to $200M Task Orders" />
                </ListItem>
              </List>
            </Grid>
            <Grid item md={12} lg={6}>
              <Typography variant="h5" color="primary.main" sx={{ fontFamily: 'Noto Sans', mt: 3 }}>Requirement Driven Technical Solutions</Typography>
              <List>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary="Capability signature diversity, measurement, and testing​" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary="CNO capability and Command and Control (C2) development" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary="Infrastructure configuration, automation, and integration​" />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary="Testing (e.g., operational, forensic, user) and reverse engineering​​" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Stack id="contact" alignItems="center" sx={{ backgroundColor: '#f2f2f2', pt: 12, pb: 12 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontFamily: 'Noto Sans',
            color: 'secondary.main',
            mb: 6,
          }}
        >
          CONTACT US
        </Typography>
        <Typography>Headquarters: 9074 Camp Rd Boonville, NY 13309</Typography>
        <Typography>
          NAICS: 541330, 541511, 541512, 541519, 541611, 541614, 541618, 541690, 541714, 541715,
          541990, 513210
        </Typography>
        <Typography>Cage Code: 8N2C0</Typography>
        <Typography>D&B: 077519995 UEI: LQ66ZVTMLNV6</Typography>
        <Typography sx={{ mb: 2 }}>Phone: 315-316-1525</Typography>
        <Typography sx={{ mb: 2 }}>Email: info@r2idef.com</Typography>
        <Box component="img" src="WOSB_Certified.png" alt="SBA WOSB Logo" sx={{ width: 150 }} />
      </Stack>
      <Stack justifyContent="space-between" alignItems="center" sx={{ pt: 8, pb: 4, backgroundColor: 'secondary.main' }}>
        <Typography sx={{ color: 'white' }}>Copyright R2I Defense, Inc.</Typography>
      </Stack>
    </>
  );
}
