import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Outlet, Link, useRouteError } from 'react-router-dom';
import theme from './theme';
import ButtonAppBar from './AppBar';

function Layout(props) {
  const { optionalComponent: OptionalComponent } = props;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        <ButtonAppBar />
        { OptionalComponent ? <OptionalComponent /> : <Outlet />}
      </>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  optionalComponent: PropTypes.elementType,
};

Layout.defaultProps = {
  optionalComponent: null,
};

function NoMatch() {
  const error = useRouteError();

  return (
    <Container>
      <Typography variant="h3" color="primary.dark" sx={{ mt: 4.0 }}>
        Error
      </Typography>
      {error
      && <Typography sx={{ mt: 2.0 }}>{error.statusText || error.message}</Typography>}
      <Typography sx={{ mt: 2.0 }}>
        <Link to="/">Click here to go home.</Link>
      </Typography>
    </Container>
  );
}

export { Layout, NoMatch };
