import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

function ButtonAppBar() {
  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 'none', pl: 4, pr: 4, pt: 2, pb: 2,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button component={Link} to="/">
          <Box component="img" src="R2I-Text.png" alt="" sx={{ height: 25 }} />
        </Button>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Button component={Link} to="/careers" variant="text" sx={{ color: 'white', display: { xs: 'none', md: 'flex' } }}>Careers</Button>
        </Stack>
      </Stack>
    </AppBar>
  );
}
export default ButtonAppBar;
